import React, { useState } from 'react';
import Header from '../components/Header.js';
import Section from '../components/Section.js';
import Footer from '../components/Footer';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Loader from '../components/Loader';
import Swal from 'sweetalert2'
import SendMail from '../utils/SendMail';
import { SENDER_EMAIL, CONTACT_EMAIL } from '../config/Keys';

//import contacto from '../images/site/contacto.png';

/**
 * Pagina de contacto
 * @returns 
 */
function Contactanos() {
    /**
     * Nombre
     */
    const [name, setName] = useState('');

     /**
      * Apellidos
      */
    const [last_name, setLastName] = useState('');
 
     /**
      * Teléfono
      */
    const [phone_number, setPhoneNumber] = useState('');
 
     /**
      * Correo
      */
    const [email, setEmail] = useState('');

    /**
     * Mensaje
     */
    const [message, setMessage] = useState('');

    /**
     * Loader
     */
     const [loader, setLoader] = useState(false);

    /**
     * Enviamos el formulario de contacto
     */
    function sendContact(){
        let errors = false;
        let messages = '';
        try{
            if(name === '' || last_name === '' || phone_number === '' 
            || email === '' || message === ''){
                errors = true;
                messages += 'Todos los campos son requeridos.<br/>';
            }
            let validEmail = new RegExp(/^[A-Za-z0-9+_.-]+@(.+)$/);
            let validPhone = new RegExp(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/);
            if(email !== '' && !email.match(validEmail)){
                errors = true;
                messages += 'El correo no es valido.<br/>';
            }
            if(phone_number !== '' && !phone_number.match(validPhone)){
                errors = true;
                messages += 'El teléfono no es valido.<br/>';
            }
            if(errors === true)
                throw messages;
            let data = {
                name: name,
                last_name: last_name,
                phone_number: phone_number,
                email: email,
                message: message
            };
            setLoader(true);
            SendMail(SENDER_EMAIL, CONTACT_EMAIL, 'Contacto','', 'templates/orangebox/contacto', data).then( (response) => {
                setLoader(false);
                if(response.status === true){
                    Swal.fire({
                        title: 'Contacto',
                        html: response.message,
                        icon: 'success',
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: '#F3781B'
                    }).then(() => {
                        setName('');
                        setLastName('');
                        setEmail('');
                        setPhoneNumber('');
                        setMessage('');
                    });
                }else{
                    Swal.fire({
                        title: 'Contacto',
                        html: response.message,
                        icon: 'warning',
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: '#F3781B'
                    });
                }
            }, (error) => {
                setLoader(false);
                Swal.fire({
                    title: 'Contacto',
                    html: error.message,
                    icon: 'warning',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#F3781B'
                });
            });
        }catch(e){
            setLoader(false);
            Swal.fire({
                title: 'Contacto',
                html: e,
                icon: 'warning',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#F3781B'
            });
        }
    }

    return (
        <>
            <Header />
            {(loader) && (
                <Loader />
            )}
            <Section fullHeight={true} className={'contact-section pt-5 pb-5'}>
                <Container className={'pb-5'}>
                    <Row className={'mt-5'}>
                        <Col xs={12} xl={{ span: 10, offset: 1 }} className={'text-center mt-3'}>
                            <h3>Contáctanos</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={{span:10, offset:1}} className={'mt-4'}>
                            <Form>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Nombres</Form.Label>
                                            <Form.Control type="text" value={name} placeholder="" onChange={(e) => {
                                                setName(e.target.value);
                                            }} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Apellidos</Form.Label>
                                            <Form.Control type="text" value={last_name} placeholder="" onChange={(e) => {
                                                setLastName(e.target.value);
                                            }} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Teléfono</Form.Label>
                                            <Form.Control maxLength={10} value={phone_number} type="number" placeholder="" onChange={(e) => {
                                                setPhoneNumber(e.target.value);
                                            }} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="text" value={email} placeholder="" onChange={(e) => {
                                                setEmail(e.target.value);
                                            }} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Group>
                                            <Form.Label>Mensaje</Form.Label>
                                            <textarea className={'form-control no-resize'} value={message} rows={5} onChange={(e) => {
                                                setMessage(e.target.value);
                                            }} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} className={'text-center mb-4'}>
                                        <Button className="btn-orange mt-5 pl-5 pr-5 pt-2 pb-2" onClick={ () => {
                                            sendContact();
                                        }}>Enviar mensaje</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Section>
            <Footer />
        </>
    );
}

export default Contactanos;