import React from 'react';
import Header from '../components/Header.js';
import Section from '../components/Section.js';
import Footer from '../components/Footer';
import { Container, Row, Col } from 'react-bootstrap';
import Products from '../config/Products';
import { Link } from 'react-router-dom';

//import escoje from '../images/site/escoje-modelo.png';

/**
 * Pagina donde se listan los tipos de cajas
 * @returns 
 */
function Personaliza(){
    return(
        <>
            <Header />
            <Section fullHeight={true} className={'personaliza-section pb-5'}>
                <Container className="full-width products-list pb-5">
                    <Row className={'pt-5 mb-5'}>
                        <Col className={'text-center'}>
                           <h2>Escoge tu modelo</h2>
                        </Col>
                    </Row>
                    <Row>
                        { Products.map((item, index) => {
                            return(
                                <Col xs={6} sm={4} md={4} lg={2} key={'img-' + index} className={'mb-4'}>
                                    <Link to={"/producto/" + item.id}>
                                        <div className={'item-image-content'}>
                                            <img src={item.images[0]} alt={item.name} />
                                        </div>
                                        <p className={'item-name'}>{item.name}</p>
                                    </Link>
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            </Section>
            <Footer/>
        </>
    );
}

export default Personaliza;