/**
 * Loader
 * @param {*} props 
 * @returns 
 */
function Loader(props){
    return(
        <div className={'content-loader'}>
            <div className={'loader'}></div>
        </div>
    );
}

export default Loader;