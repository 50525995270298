import React from 'react';
import { Modal, Tabs, Tab, Row, Col } from 'react-bootstrap';
import offset from '../images/disenio/offset.png';
import digital from '../images/disenio/digital.png';
import flexografia from '../images/disenio/flexografia.png';

/**
 * Modal que muestra los tipos de impresión
 * @returns 
 */
function ModalImpresion(props){
    return(
        <Modal 
            show={props.show} 
            backdrop="static"
            keyboard={false}
            onHide={() => {
                props.close();
            }}
            size={'lg'}
            className={'modal-impresion'}
            >
            <Modal.Header closeButton>
                
            </Modal.Header>
            <Modal.Body>
                <Tabs defaultActiveKey="offset" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="offset" title="impresión Offset">
                        <Row>
                            <Col xs={12} md={5}>
                                <img src={offset} />
                            </Col>
                             <Col xs={12} md={7}>
                                <h3>Offset</h3>
                                <p>El Offset es un tipo de impresión que deriva de la litografía, y emplea impresión de CMYK (con 4 tintas). Su funcionamiento es el siguiente: un laser graba sobre una plancha, generalmente de caucho, el diseño que queremos imprimir, por lo que se le aplica tinta a la plancha marcada. Después, el material va pasando por los rodillos marcados, donde la impresión queda plasmada. Hace que la impresión quede muy nítida y de alta calidad debido a que no imprimimos directamente sobre el papel, si no que es el caucho quien recibo la primera tinta. Por lo tanto, los poros absorben el sobrante de tinta y no queda plasmado en el material.</p>
                                <p>Como puntos negativos, no todas las impresoras pueden imprimir con esta técnica, ya que necesitan planchas especiales para llevar el material que son muy costosas. Tampoco pueden manipularse el trabajo hasta pasadas las 24 horas, además de que todos los impresos han de ser iguales, ya que son tiradas largas.</p>
                                <p>Nosotros recomendamos este tipo de impresión cuando las vas a utilizar como caja de venta y con una intención más exclusiva y de edición limitada.</p>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="digital" title="impresión Digital">
                        <Row>
                            <Col xs={12} md={5}>
                                 <img src={digital} />
                            </Col>
                             <Col xs={12} md={7}>
                                <h3>Digital</h3>
                                <p>También conocida raramente como “Ink Jet”, Este tipo de impresión suele utilizarse en etiquetas, prototipos, cajas con cartón corrugado y también en las laminadas, así como en los film para packagings flexibles. Las ventajas que tiene esta técnica es que no tiene coste a la hora de montar módulos especiales, además de imprimir relativamente rápido. Por ello, los pedidos mínimos para que salga rentable son bajos con una calidad inferior a la de la litografía.</p>
                                <p>Sin embargo, como punto negativo, es que hacer pedidos de gran volumen es caro ya que el coste por unidad es más alto, por lo tanto es recomendable si necesitas cajas de venta y de envío pero en pocas cantidades.</p>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="serigrafia" title="impresión Serigrafía">
                        <Row>
                            <Col xs={12} md={5}>
                                 <img src={flexografia} />
                            </Col>
                             <Col xs={12} md={7}>
                                <h3>Flexografía</h3>
                                <p>También conocida como “Flexo”, funciona a la perfección con packagings flexibles y sobre cartón corrugado. Como parte positiva, este tipo de impresión tiene un bajo coste por unidad además de que las piezas necesarias para su impresión también son baratas. Además se pueden utilizar tintas acuosas y aceitosas.</p>
                                <p>Aun así, también tiene algunos puntos negativos como que tiene peor calidad de impresión que la litografía, o que a la hora de utilizar colores suaves o en procesos de 4 colores, la impresión empieza a dar problemas. Los colores en flexografía están limitados (aunque tiene un catálogo con varias referencias) pero al “mezclarse” con la superficie del cartón, no acostumbra a tener un resultado muy vibrante.</p>
                                <p>Por lo tanto, este tipo de impresión es recomendable cuando vas a hacer cajas de envío con fondos impresos o con logos que pueden verse.</p>
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>
            </Modal.Body>
        </Modal>
    );
}

export default ModalImpresion;