import React from 'react';
import Header from '../components/Header.js';
import Section from '../components/Section.js';
import Footer from '../components/Footer';
import { Row, Container, Col } from 'react-bootstrap';
/**
 * Pagina que muestra el aviso de privacidad
 * @returns 
 */
function AvisoPrivacidad(){
    return(
        <>
            <Header />
            <Section fullHeight={true} className={'aviso-privacidad-section pb-5'}>
                <Container>
                    <Row>
                        <Col>
                            <h2 className="text-center pt-5 mb-0 titulo-legales">Términos y Condiciones</h2>
                            <h2 className="text-center pt-0 titulo-legales">Aviso de Privacidad</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-4 pb-5 pb-md-1">
                            <p className="pl-md-5 pr-md-5 text-justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae nibh et augue lacinia aliquam. Etiam sit amet malesuada libero. Duis rutrum porttitor augue, id placerat ante ullamcorper in. Suspendisse tristique urna non enim maximus iaculis. Nam sollicitudin sollicitudin dapibus. Suspendisse dictum lectus et lorem venenatis malesuada. Vestibulum convallis leo eu massa suscipit scelerisque.</p>
                            <p className="pl-md-5 pr-md-5 text-justify">Integer malesuada eu ipsum non lobortis. Nunc ultrices consequat elit, vitae luctus magna vestibulum pharetra. Praesent a eleifend ex. Integer aliquet lectus enim, sed pellentesque turpis consectetur vitae. Cras imperdiet elementum maximus. Praesent vehicula rhoncus sem, et pellentesque lectus auctor in. Nunc mattis augue vel lobortis finibus. Donec quam velit, condimentum at congue et, laoreet ut elit. Sed finibus mollis enim, ultrices dapibus quam convallis et.</p>
                            <p className="pl-md-5 pr-md-5 text-justify">Donec vitae elit a dui mattis imperdiet eu nec nulla. Phasellus placerat purus ac quam sollicitudin, at viverra erat luctus. Maecenas pellentesque nunc lectus, eu mattis urna dignissim tincidunt. Quisque ac pharetra magna, in mattis lectus. Aliquam consectetur eros sed gravida egestas. Sed eu tortor eget neque tempor posuere. Aenean mattis in nunc id pretium. Nulla non sem suscipit, fringilla ipsum eu, blandit nisl. Donec interdum libero non nunc facilisis porta. Duis augue dui, venenatis ac augue dapibus, ornare finibus nibh. Vivamus suscipit ex eget rhoncus egestas. Fusce fermentum justo vel ante aliquet sodales. Vivamus eu mattis enim. Aliquam dictum eleifend nisi sit amet placerat.</p>
                            <p className="pl-md-5 pr-md-5 text-justify">In dolor tellus, tempor ac sem ac, accumsan rutrum lacus. Vestibulum finibus libero quis enim ornare pharetra. In eu est nec mi rutrum tempor. Nullam bibendum felis a tellus sagittis semper. Vivamus varius leo non venenatis placerat. Cras eu dictum libero. Aenean vehicula ligula vel massa auctor, non ullamcorper ipsum ultricies. Nullam faucibus blandit augue, eget suscipit risus pretium eget.</p>
                            <p className="pl-md-5 pr-md-5 text-justify">Vivamus egestas tempor elit ut fermentum. Mauris porta, risus eget semper ultrices, magna leo faucibus metus, nec placerat est metus eleifend neque. Fusce commodo est egestas feugiat molestie. Nullam rutrum in ante at volutpat. Aliquam nunc est, iaculis et mi eget, cursus dapibus mi. Integer tincidunt orci vel tristique dignissim. Integer euismod id turpis vitae sollicitudin. Ut vitae volutpat nisi, et iaculis mauris. Donec volutpat et leo in tristique. Integer est dolor, venenatis a enim vel, elementum egestas justo. Quisque vulputate mi eu massa pulvinar tincidunt. Phasellus metus libero, commodo vitae urna dapibus, dignissim consectetur magna.</p>
                            <p className="pl-md-5 pr-md-5 text-justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae nibh et augue lacinia aliquam. Etiam sit amet malesuada libero. Duis rutrum porttitor augue, id placerat ante ullamcorper in. Suspendisse tristique urna non enim maximus iaculis. Nam sollicitudin sollicitudin dapibus. Suspendisse dictum lectus et lorem venenatis malesuada. Vestibulum convallis leo eu massa suscipit scelerisque.</p>
                            <p className="pl-md-5 pr-md-5 text-justify">Integer malesuada eu ipsum non lobortis. Nunc ultrices consequat elit, vitae luctus magna vestibulum pharetra. Praesent a eleifend ex. Integer aliquet lectus enim, sed pellentesque turpis consectetur vitae. Cras imperdiet elementum maximus. Praesent vehicula rhoncus sem, et pellentesque lectus auctor in. Nunc mattis augue vel lobortis finibus. Donec quam velit, condimentum at congue et, laoreet ut elit. Sed finibus mollis enim, ultrices dapibus quam convallis et.</p>
                            <p className="pl-md-5 pr-md-5 text-justify">Donec vitae elit a dui mattis imperdiet eu nec nulla. Phasellus placerat purus ac quam sollicitudin, at viverra erat luctus. Maecenas pellentesque nunc lectus, eu mattis urna dignissim tincidunt. Quisque ac pharetra magna, in mattis lectus. Aliquam consectetur eros sed gravida egestas. Sed eu tortor eget neque tempor posuere. Aenean mattis in nunc id pretium. Nulla non sem suscipit, fringilla ipsum eu, blandit nisl. Donec interdum libero non nunc facilisis porta. Duis augue dui, venenatis ac augue dapibus, ornare finibus nibh. Vivamus suscipit ex eget rhoncus egestas. Fusce fermentum justo vel ante aliquet sodales. Vivamus eu mattis enim. Aliquam dictum eleifend nisi sit amet placerat.</p>
                            <p className="pl-md-5 pr-md-5 text-justify">In dolor tellus, tempor ac sem ac, accumsan rutrum lacus. Vestibulum finibus libero quis enim ornare pharetra. In eu est nec mi rutrum tempor. Nullam bibendum felis a tellus sagittis semper. Vivamus varius leo non venenatis placerat. Cras eu dictum libero. Aenean vehicula ligula vel massa auctor, non ullamcorper ipsum ultricies. Nullam faucibus blandit augue, eget suscipit risus pretium eget.</p>
                            <p className="pl-md-5 pr-md-5 text-justify">Vivamus egestas tempor elit ut fermentum. Mauris porta, risus eget semper ultrices, magna leo faucibus metus, nec placerat est metus eleifend neque. Fusce commodo est egestas feugiat molestie. Nullam rutrum in ante at volutpat. Aliquam nunc est, iaculis et mi eget, cursus dapibus mi. Integer tincidunt orci vel tristique dignissim. Integer euismod id turpis vitae sollicitudin. Ut vitae volutpat nisi, et iaculis mauris. Donec volutpat et leo in tristique. Integer est dolor, venenatis a enim vel, elementum egestas justo. Quisque vulputate mi eu massa pulvinar tincidunt. Phasellus metus libero, commodo vitae urna dapibus, dignissim consectetur magna.</p>
                        </Col>
                    </Row>
                </Container>
            </Section>
            <Footer/>
        </>
    );
}

export default AvisoPrivacidad;