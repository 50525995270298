import React from 'react';
import Header from '../components/Header.js';
import Section from '../components/Section.js';
import Button from 'react-bootstrap/Button';
import Footer from '../components/Footer';
import { Row, Col } from 'react-bootstrap';

import logoHome from '../images/site/logo-home.png';
import iconHome from '../images/site/icon-home.png';

/**
 * Pagina principal muestra la introducción del sitio
 * @returns 
 */
function Home(){
    return(
        <>
            <Header />
            <Section fullHeight={true} className={'home-section pb-5'}>
                <div className={'full-container pb-5 pl-xl-5 pr-xl-5'}>
                    <Row className={'pt-5'}>
                        <Col xs={12} md={4} className={'text-center text-md-center'}>
                            <img src={logoHome} alt={'OrangeBox'} className={'home-logo'}/>
                        </Col>
                        <Col xs={12} md={8} className={'align-items-center d-flex'}>
                            <h4 className={'mt-5 mt-md-0'}>Tu caja en 3 pasos</h4>
                        </Col>
                    </Row>
                    <Row className={'mt-5'}>
                        <Col xs={12} md={4}>
                            <div className={'item-home mt-0 mt-md-5'}>
                                <img src={iconHome} alt="Icon Home" />
                                <h5 className={'first'}>1 Diseña</h5>
                                <p>Diseña tu caja de acuerdo a tus necesidades y gustos.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <div className={'item-home mt-5'}>
                                <img src={iconHome} alt="Icon Home" />
                                <h5 className={'second'}>2 Cotiza</h5>
                                <p>Tendrás atención personalizada con nuestros colaboradores vía whatsapp.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <div className={'item-home mt-5'}>
                                <img src={iconHome} alt="Icon Home" />
                                <h5 className={'third'}>3 Recibe</h5>
                                <p>Tu pedido llegará a tus puertas en un periodo de 5 a 7 días.</p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className={'w-100 text-center mt-4 mb-5'}>
                    <Button href="/personaliza" className="button-orange-home">PERSONALIZA</Button>
                </div>
            </Section>
            <Footer/>
        </>
    );
}

export default Home;