/**
 * Imágenes que contiene la galería
 */
const images = [
    "https://picsum.photos/200/300?image=1050",
    "https://picsum.photos/300/300?image=206",
    "https://picsum.photos/300/500?image=340",
    "https://picsum.photos/500/300?image=190",
    "https://picsum.photos/300/300?image=130",
    "https://picsum.photos/300/300?image=222",
    "https://picsum.photos/300/500?image=342",
    "https://picsum.photos/200/300?image=123",
    "https://picsum.photos/300/300?image=780",
    "https://picsum.photos/300/500?image=450",
    "https://picsum.photos/500/300?image=560",
    "https://picsum.photos/300/300?image=290",
    "https://picsum.photos/300/300?image=380",
    "https://picsum.photos/300/500?image=286",
    "https://picsum.photos/300/300?image=403",
    "https://picsum.photos/200/200?image=215",
];

export default images;