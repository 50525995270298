import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-router-dom";

import logo from '../images/site/logo-header.png';
import iconInstagram from '../images/site/instagram.png';
import iconWhatsapp from '../images/site/whatsapp.png';

import { INSTAGRAM_LINK, WHATSAPP_PHONE} from '../config/Keys.js';

/**
 * Cabecera del sitio
 * @returns 
 */
function Header(){
    return(
        <Navbar className="header-site sticky-top" expand="lg" id="main-header">
            <Navbar.Brand>
                <Link to="/">
                    <img src={logo} alt={'Logo'} className={'ml-0 ml-md-4 ml-lg-2 ml-xl-5'}/>
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="justify-content-end align-items-center w-100">
                    <Link to="/personaliza" className="nav-link">Personaliza</Link>
                    <Link to="/quienes-somos" className="nav-link">Quiénes somos</Link>
                    <Link to="/galeria" className="nav-link">Galería</Link>
                    <Link to="/contactanos" className="nav-link">Contáctanos</Link>
                    <div className={'social-header'}>
                        <a href={ INSTAGRAM_LINK } target={'_blank'}>
                            <img src={iconInstagram} alt={'Instagram'}/>
                        </a>
                        <a href={'https://wa.me/' + WHATSAPP_PHONE} target={'_blank'}>
                            <img src={iconWhatsapp} alt={'WhatsApp'}/>
                        </a>
                    </div>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Header;