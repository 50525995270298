const Products = [
    {
        id: 1,
        name: 'Bottom and Lid',
        description: 'Caja rectangular automontable para regalo, perfecta para decoraciones craft y handmade. ¡Descubre nuestras nuevas cajas en blanco y negro, perfectas para dar un toque original a tus regalos o envíos! Los colores negro y blanco tienen la cara interior en color kraft. ',
        images: [
            'images/gallery/img-caja.png',
            'images/gallery/img-caja.png',
            'images/gallery/img-caja.png'
        ],
        gallery: []
    },
    {
        id: 2,
        name: 'Business',
        description: 'Caja rectangular automontable para regalo, perfecta para decoraciones craft y handmade. ¡Descubre nuestras nuevas cajas en blanco y negro, perfectas para dar un toque original a tus regalos o envíos! Los colores negro y blanco tienen la cara interior en color kraft. ',
        images: [
            'images/gallery/img-caja.png',
            'images/gallery/img-caja.png',
            'images/gallery/img-caja.png'
        ],
        gallery: []
    },
    {
        id: 3,
        name: 'Casket',
        description: 'Caja rectangular automontable para regalo, perfecta para decoraciones craft y handmade. ¡Descubre nuestras nuevas cajas en blanco y negro, perfectas para dar un toque original a tus regalos o envíos! Los colores negro y blanco tienen la cara interior en color kraft. ',
        images: [
            'images/gallery/img-caja.png',
            'images/gallery/img-caja.png',
            'images/gallery/img-caja.png'
        ],
        gallery: []
    },
    {
        id: 4,
        name: 'Clam',
        description: '<strong>Caja rectangular</strong> automontable para regalo, perfecta para decoraciones craft y handmade. ¡Descubre nuestras nuevas cajas en blanco y negro, perfectas para dar un toque original a tus regalos o envíos! Los colores negro y blanco tienen la cara interior en color kraft. ',
        images: [
            'images/gallery/img-caja.png',
            'images/gallery/img-caja.png',
            'images/gallery/img-caja.png',
            'images/gallery/img-caja.png',
            'images/gallery/img-caja.png'
        ],
        gallery: []
    },
    {
        id: 5,
        name: 'Delivery',
        description: 'Caja rectangular automontable para regalo, perfecta para decoraciones craft y handmade. ¡Descubre nuestras nuevas cajas en blanco y negro, perfectas para dar un toque original a tus regalos o envíos! Los colores negro y blanco tienen la cara interior en color kraft. ',
        images: [
            'images/gallery/img-caja.png',
            'images/gallery/img-caja.png',
            'images/gallery/img-caja.png'
        ],
        gallery: []
    },
    {
        id: 6,
        name: 'Events',
        description: 'Caja rectangular automontable para regalo, perfecta para decoraciones craft y handmade. ¡Descubre nuestras nuevas cajas en blanco y negro, perfectas para dar un toque original a tus regalos o envíos! Los colores negro y blanco tienen la cara interior en color kraft. ',
        images: [
            'images/gallery/img-caja.png',
            'images/gallery/img-caja.png',
            'images/gallery/img-caja.png'
        ],
        gallery: []
    },
    {
        id: 7,
        name: 'Exhibitor',
        description: 'Caja rectangular automontable para regalo, perfecta para decoraciones craft y handmade. ¡Descubre nuestras nuevas cajas en blanco y negro, perfectas para dar un toque original a tus regalos o envíos! Los colores negro y blanco tienen la cara interior en color kraft. ',
        images: [
            'images/gallery/img-caja.png',
            'images/gallery/img-caja.png',
            'images/gallery/img-caja.png'
        ],
        gallery: []
    },
    {
        id: 9,
        name: 'Holidays',
        description: 'Caja rectangular automontable para regalo, perfecta para decoraciones craft y handmade. ¡Descubre nuestras nuevas cajas en blanco y negro, perfectas para dar un toque original a tus regalos o envíos! Los colores negro y blanco tienen la cara interior en color kraft. ',
        images: [
            'images/gallery/img-caja.png',
            'images/gallery/img-caja.png',
            'images/gallery/img-caja.png'
        ],
        gallery: []
    },
    {
        id: 10,
        name: 'Match',
        description: 'Caja rectangular automontable para regalo, perfecta para decoraciones craft y handmade. ¡Descubre nuestras nuevas cajas en blanco y negro, perfectas para dar un toque original a tus regalos o envíos! Los colores negro y blanco tienen la cara interior en color kraft. ',
        images: [
            'images/gallery/img-caja.png',
            'images/gallery/img-caja.png',
            'images/gallery/img-caja.png'
        ],
        gallery: []
    },
    {
        id: 11,
        name: 'Party',
        description: 'Caja rectangular automontable para regalo, perfecta para decoraciones craft y handmade. ¡Descubre nuestras nuevas cajas en blanco y negro, perfectas para dar un toque original a tus regalos o envíos! Los colores negro y blanco tienen la cara interior en color kraft. ',
        images: [
            'images/gallery/img-caja.png',
            'images/gallery/img-caja.png',
            'images/gallery/img-caja.png'
        ],
        gallery: []
    },
    {
        id: 12,
        name: 'Shelf',
        description: 'Caja rectangular automontable para regalo, perfecta para decoraciones craft y handmade. ¡Descubre nuestras nuevas cajas en blanco y negro, perfectas para dar un toque original a tus regalos o envíos! Los colores negro y blanco tienen la cara interior en color kraft. ',
        images: [
            'images/gallery/img-caja.png',
            'images/gallery/img-caja.png',
            'images/gallery/img-caja.png'
        ],
        gallery: []
    },
    {
        id: 13,
        name: 'STD',
        description: 'Caja rectangular automontable para regalo, perfecta para decoraciones craft y handmade. ¡Descubre nuestras nuevas cajas en blanco y negro, perfectas para dar un toque original a tus regalos o envíos! Los colores negro y blanco tienen la cara interior en color kraft. ',
        images: [
            'images/gallery/img-caja.png',
            'images/gallery/img-caja.png',
            'images/gallery/img-caja.png'
        ],
        gallery: []
    }
];

export default Products;