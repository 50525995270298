import React from 'react';
import Header from '../components/Header.js';
import Section from '../components/Section.js';
import Footer from '../components/Footer';
import { Container, Row, Col } from 'react-bootstrap';

import logoHome from '../images/site/logo-home.png';
/**
 * Pagina Quienes somos
 * @returns 
 */
function QuienesSomos(){
    return(
        <>
            <Header />
            <Section fullHeight={true} className={'aboutus-section pt-5 pb-5'}>
                <Container>
                    <Row className={'mt-5'}>
                        <Col xs={12} className={'text-center'}>
                            <img src={logoHome} alt={'Logo'}/>
                        </Col>
                        <Col xs={12} xl={{span:10, offset:1}} className={'text-center mt-5'}>
                            <p>Somos una empresa orgullosamente mexicana, enfocada en la venta de empaques con el fin de apoyar a todas   aquellas   Pymes   y   emprendedores   que   no   cuentan   con   un volumen   alto   de consumo. </p>
                            <p className={'mt-4'}>Todas nuestras cajas serán totalmente personalizadas,  y   te  permitirá hacer  pedidos pequeños, medianos o grandes a precios accesibles. </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} xl={{span:10, offset:1}} className={'mb-5'}>
                            <div className={'w-100 mt-5 mb-5 text-center'}>
                                <h3 className={'text-shadow'}>Mecánica</h3>
                            </div>
                            <h5>Escoje tu modelo</h5>
                            <p className={'mb-4 text-justify'}>Contamos con una agran variedad de modelos para empezar el proceso de personalización de tu caja, escoge el qué más se acomode a tus necesidades y gustos.</p>
                            <h5>Diseña</h5>
                            <p className={'mb-4 text-justify'}>Se debe seleccionar el número de piezas requeridas, medidas de la caja y tipo de impresión que vas a necesitar.</p>
                            <h5>Cotización</h5>
                            <p className={'mb-4 text-justify'}>Al requerir una cotización se pondrá en contacto uno de nuestros colaboradores vía whatsapp.</p>
                            <p className={'mb-4 text-justify'}>Se te enviará tu cotización y liga de pago para poder empezar el proceso de diseño personalizado.</p>
                            <p className={'mb-4 text-justify'}>Una vez confirmado el pago el colabordor trabajará contigo para diseñar la impresión de tu caja de acuerdo a tus gustos y necesidades.</p>
                            <h5>Recibe</h5>
                            <p className={'mb-4 text-justify'}>Una vez terminado y aprobado el diseño recibirás tu pedido en un periodo de 5 a 7 días.</p>
                        </Col>
                    </Row>
                </Container>
            </Section>
            <Footer/>
        </>
    );
}

export default QuienesSomos;