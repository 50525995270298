import React, { useEffect, useState } from 'react';

function Section(props){
    /**
     * asignamos el minimo de alto
     */
    const [minHeight, setMinHeight] = useState(0);

    /**
     * asignamos valores iniciales
     */
    useEffect(() => {
        function handleResize(){
            if(typeof props.fullHeight !== undefined && props.fullHeight === true){
                let headerHeight = 0;
                if(document.querySelector('#main-header') !== null)
                    headerHeight = document.querySelector('#main-header').clientHeight;
                let windowHeight = window.innerHeight
                setMinHeight(windowHeight - ( headerHeight + 7));
            }
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return() => {
            setMinHeight(0);
        }
    }, [props]);

    /**
     * renderizamos
     */
    return(
        <section style={{minHeight: minHeight + 'px'}} className={props.className}>
            {props.children}
        </section>
    );
};

export default Section;