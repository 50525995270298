import React, { useEffect, useState } from 'react';
import Header from '../components/Header.js';
import Section from '../components/Section.js';
import Footer from '../components/Footer';
import { useParams } from 'react-router-dom';
import Products from '../config/Products';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import Loader from '../components/Loader';
import Swal from 'sweetalert2'
import SendMail from '../utils/SendMail';
import { useNavigate } from "react-router-dom";
import { SENDER_EMAIL } from '../config/Keys';
import ModalImpresion from '../components/ModalImpresion.js';


/**
 * Pagina que muestra el detalle del producto
 * @returns 
 */
function Product() {
    /**
     * Navegador
     */
    const navigate = useNavigate();

    /**
     * Definimos el ID del producto recibido
     */
    let { id } = useParams();

    /**
     * Producto
     */
    const [product, setProduct] = useState({
        gallery:[]
    });

    /**
     * Cantidad
     */
    const [quantity, setQuantity] = useState(0);

    /**
     * Impresión
     */
    const [impresion, setImpresion] = useState('');

    /**
     * Nombre
     */
    const [name, setName] = useState('');

    /**
     * Apellidos
     */
    const [last_name, setLastName] = useState('');

    /**
     * Teléfono
     */
    const [phone_number, setPhoneNumber] = useState('');

    /**
     * Correo
     */
    const [email, setEmail] = useState('');

    /**
     * Código postal
     */
    const [postal_code, setPostalCode] = useState('');

    /**
     * Ciudad
     */
    const [city, setCity] = useState('');

    /**
     * Dirección
     */
    const [address, setAddress] = useState('');

    /**
     * Estado
     */
    const [state, setState] = useState('');

    /**
     * Alto
     */
    const [hight, setHight] = useState('');

    /**
     * Largo
     */
    const [length, setLength] = useState('');

    /**
     * Ancho
     */
    const [width, setWidth] = useState('');

    /**
     * Loader
     */
    const [loader, setLoader] = useState(false);

    /**
     * 
     */
    const [showModal, setShowModal] = useState(false);

    /**
     * 
     */
    useEffect(() => {
        let BreakException = {};
        if(isNaN(id)){
            navigate('/notfund');
        }
        try {
            Products.forEach((item) => {
                if (item.id === parseInt(id)) {
                    initData(item);
                    throw BreakException;
                }
            });
        } catch (e) {
            if (e !== BreakException) throw e;
        }
    }, [id, product, quantity, name, last_name, email, phone_number, postal_code, address, city, state, impresion, hight, length, width]);

    /**
     * Cargamos los datos del producto correspondiente
     * @param {*} item 
     */
    function initData(item) {
        let gallery = [];
        let origin = window.location.origin + '/';
        item.images.forEach((image) => {
            gallery.push({
                original: origin + image,
                thumbnail: origin + image
            });
        });
        item.gallery = gallery;
        setProduct(item);
    }

    /**
     * Enviamos la cotización
     * Se debe validar toda la información
     */
    async function sendPrices(){
        try{
            let errors = false;
            let messages = '';
            if(quantity === '' || quantity <= 0){
                errors = true;
                messages += 'La cantidad de cajas es requerido.<br/>';
            }
            if(hight === '' || hight <= 0 || length === '' || length <= 0 || width === '' || width <= 0){
                errors = true;
                messages += 'Las medidas son requeridas.<br/>';
            }
            if(impresion === ''){
                errors = true;
                messages += 'El tipo de impresión es requerido.<br/>';
            }
            if(name === '' || last_name === '' || phone_number === '' 
            || email === '' || postal_code === '' || city === '' 
            || address === '' || state === ''){
                errors = true;
                messages += 'Todos los campos de la dirección son requeridos.<br/>';
            }
            let validEmail = new RegExp(/^[A-Za-z0-9+_.-]+@(.+)$/);
            let validPhone = new RegExp(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/);
            let validCP = new RegExp(/^([1-9]{2}|[0-9][1-9]|[1-9][0-9])[0-9]{3}$/);

            if(email !== '' && !email.match(validEmail)){
                errors = true;
                messages += 'El correo no es valido.<br/>';
            }

            if(phone_number !== '' && !phone_number.match(validPhone)){
                errors = true;
                messages += 'El teléfono no es valido.<br/>';
            }

            if(postal_code !== '' && !postal_code.match(validCP)){
                errors = true;
                messages += 'El código postal no es valido.<br/>';
            }
            if(errors === true)
                throw messages;
            
            let data = {};
            data.name = product.name;
            data.quantity = quantity;
            data.measures = {
                hight: hight,
                length: length,
                width: width
            };
            data.impresion = impresion;
            data.address = {
                name: name,
                last_name: last_name,
                email: email,
                phone_number: phone_number,
                address: address,
                city: city,
                state: state,
                postal_code: postal_code
            };
            SendMail(SENDER_EMAIL, email, 'Cotización','', 'templates/orangebox/cotizacion', data).then( (response) => {
                setLoader(false);
                if(response.status === true){
                    navigate('/gracias');
                }else{
                    Swal.fire({
                        title: 'Cotización',
                        html: response.message,
                        icon: 'warning',
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: '#F3781B'
                    });
                }
            }, (error) => {
                setLoader(false);
                Swal.fire({
                    title: 'Cotización',
                    html: error.message,
                    icon: 'warning',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#F3781B'
                });
            });
        }catch(e){
            setLoader(false);
            Swal.fire({
                title: 'Cotización',
                html: e,
                icon: 'warning',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#F3781B'
            });
        }
    }

    function getThumbnailPosition(){
        const { innerWidth: width, innerHeight: height } = window;
        if(width < 768)
            return 'bottom';
        return 'left';
    }

    return (
        <>
            <Header />
            {(loader) && (
                <Loader />
            )}
            <ModalImpresion show={showModal}  close={() => {
                setShowModal(false);
            }}/>
            <Section fullHeight={true} className={'product-detail-section pb-t'}>
                <Container className={'pb-5'}>
                    <Row>
                        <Col className={'text-center mt-5'}>
                            <h2>Diseña</h2>
                        </Col>
                    </Row>
                    <Row className={'mt-3 mt-lg-4'}>
                        <Col xs={12} md={7} >
                            <Row>
                                <Col xs={12}>
                                    <h1 className={'product-name'}>{product.name}</h1>
                                    <p className={'product-description'} dangerouslySetInnerHTML={{__html: product.description}}></p>
                                </Col>
                                <Col xs={12}>
                                {(product.gallery.length > 0) && (
                                    <ImageGallery  
                                    items={product.gallery} 
                                    thumbnailPosition={getThumbnailPosition()} 
                                    showFullscreenButton={false} 
                                    showNav={false} 
                                    showPlayButton={false}/>
                                )}
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={5} className={'mt-4 mt-md-0'}>
                            <h3 className={'product-subtitle mb-0 mt-4 mt-md-0'}>Unidades</h3>
                            <div className={'range-content'}>
                                <input type="range"
                                step="1"
                                min="0"
                                max="10000"
                                className={'quantity-range'}
                                onChange={ (e) => {
                                    setQuantity(parseInt(e.target.value));
                                }}
                                value={quantity} />
                                <p className={'input-quantity'}>{quantity}</p>
                            </div>
                            <h3 className={'product-subtitle mt-4'}>Medidas</h3>
                            <div className={'measures-content'}>
                                <div className={'measure-item'}>
                                    <label><strong>A</strong> - Alto</label>
                                    <input type={'number'} value={hight} onChange={ (e) => {
                                        if(!isNaN(e.target.value)){
                                            setHight(e.target.value);
                                        }else{
                                            setHight('');
                                        }
                                    }}/>
                                    <label> <strong>cm</strong></label>
                                </div>
                                <div className={'measure-item'}>
                                    <label><strong>B</strong> - Ancho</label>
                                    <input type={'number'} value={width} onChange={ (e) => {
                                        if(!isNaN(e.target.value)){
                                            setWidth(e.target.value);
                                        }else{
                                            setWidth('');
                                        }
                                    }}/>
                                    <label> <strong>cm</strong></label>
                                </div>
                                <div className={'measure-item'}>
                                    <label><strong>C</strong> - Largo</label>
                                    <input type={'number'} value={length} onChange={ (e) => {
                                        if(!isNaN(e.target.value)){
                                            setLength(e.target.value);
                                        }else{
                                            setLength('');
                                        }
                                    }}/>
                                    <label> <strong>cm</strong></label>
                                </div>
                            </div>
                            <h3 className={'product-subtitle mt-4'}>Impresión</h3>
                            <div className={'impresion-content'}>
                                <select onChange={ (e) => {
                                    let indexSelected = e.target.options.selectedIndex;
                                    let option = e.target.options[indexSelected];
                                    setImpresion(option.innerText);
                                }}>
                                    <option value={""}>&nbsp;</option>
                                    <option value={'Serigrafía'}>Serigrafía</option>
                                    <option value={'Offset'}>Offset</option>
                                    <option value={'Digital'}>Digital</option>
                                </select>
                                <Button className={'btn-ejemplos-impresion'} onClick={() => {
                                    setShowModal(true);
                                }}>Ejemplos de impresión</Button>
                            </div>
                        </Col>
                    </Row>
                    <Row className={'mt-4 pb-5'}>
                        <Col xs={12}>
                            <Row>
                                <Col xs={12} md={{span:10, offset:1}}>
                                    <h6 className={'mt-4 text-center product-name'}>Datos de envío</h6>
                                    <div className={'text-center w-100'}>

                                    </div>
                                    <Form className={'mt-4 address-form'}>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Nombres</Form.Label>
                                                    <Form.Control type="text" value={name} placeholder="" onChange={ (e) => {
                                                        setName(e.target.value);
                                                    }}/>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Apellidos</Form.Label>
                                                    <Form.Control type="text" value={last_name} placeholder="" onChange={ (e) => {
                                                        setLastName(e.target.value);
                                                    }}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Teléfono</Form.Label>
                                                    <Form.Control maxLength={10} value={phone_number} type="number" placeholder="" onChange={ (e) => {
                                                        setPhoneNumber(e.target.value);
                                                    }}/>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control type="text" value={email} placeholder="" onChange={ (e) => {
                                                        setEmail(e.target.value);
                                                    }}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Código Postal</Form.Label>
                                                    <Form.Control maxLength={5} value={postal_code}  type="number" placeholder="" onChange={ (e) => {
                                                        setPostalCode(e.target.value);
                                                    }}/>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Localidad / Ciudad</Form.Label>
                                                    <Form.Control type="text" value={city} placeholder="" onChange={ (e) => {
                                                        setCity(e.target.value);
                                                    }}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Dirección</Form.Label>
                                                    <Form.Control type="text" value={address} placeholder="" onChange={ (e) => {
                                                        setAddress(e.target.value);
                                                    }}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Estado</Form.Label>
                                                    <Form.Control type="text" value={state} placeholder="" onChange={ (e) => {
                                                        setState(e.target.value);
                                                    }}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Row>
                                <Col xs={12} md={{span:10, offset:1}}>
                                    <div className={'address-form'}>
                                        {/*<h3 className={'product-subtitle mt-4'}>Notas:</h3>*/}
                                        <h6 className={'mt-4 mb-0 product-name'}>Notas:</h6>
                                        <p className={'mt-1 nota-message'}>Al requerir una cotización se pondrá en contacto uno de nuestros colaboradores vía whatsapp, se te enviará tu cotización y liga de pago para poder empezar el proceso de diseño personalizado. Una vez confirmado el pago el colabordor trabajará contigo para diseñar la impresión de tu caja de acuerdo a tus gustos y necesidades.</p>
                                        <Row>
                                            <Col xs={12} className={'text-center mt-4 mb-4'}>
                                                <Button className={'btn-orange'} onClick={(e) => {
                                                    setLoader(true);
                                                    sendPrices();
                                                }}>Envíar a cotización</Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Section>
            <Footer />
        </>
    );
}

export default Product;