import React from 'react';
import Header from '../components/Header.js';
import Section from '../components/Section.js';
import Footer from '../components/Footer';
import { Container, Row, Col, Button } from 'react-bootstrap';

//import gracias from '../images/site/gracias.png';

/**
 * Pagina principal muestra la introducción del sitio
 * @returns 
 */
function Gracias(){
    return(
        <>
            <Header />
            <Section fullHeight={true} className={'thanks-section'}>
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={{span:10, offset:1}} xl={{span:8, offset:2}} className={'text-center'}>
                            <h3>GRACIAS</h3>
                            <p className={'mb-5'}>En un momento un colaborador se pondrá en contacto para poder afinar los detalles de tus diseño y entregarte tu cotización personalizada.</p>
                            <Button href="/personaliza" className="btn-orange mt-5">Seguir Personalizando</Button>
                        </Col>
                    </Row>
                </Container>
            </Section>
            <Footer/>
        </>
    );
}

export default Gracias;