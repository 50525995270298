// ScrollToTop.jsx
import { useEffect } from "react";
import { useLocation } from "react-router";

/**
 * Componente que envía al top cuando se carga un nuevo link
 * @param {*} props 
 * @returns 
 */
function ScrollToTop (props){
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return <>{props.children}</>
};

export default ScrollToTop;