import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import TerminosCondiciones from './pages/TerminosCondiciones';
import AvisoPrivacidad from './pages/AvisoPrivacidad';
import Personaliza from './pages/Personaliza';
import Product from './pages/Product';
import Gracias from './pages/Gracias';
import QuienesSomos from './pages/QuienesSomos';
import Contactanos from './pages/Contactanos';
import Galeria from './pages/Galeria';

import ScrollToTop from './components/ScrollToTop';
import NotFound from './pages/NotFound';

/**
 * BrowserRouter maneja las páginas del sitio
 * @returns 
 */
function App() {
    return (
        <div>
            <BrowserRouter>
                <ScrollToTop />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/terminos-y-condiciones" element={<TerminosCondiciones />} />
                    <Route path="/aviso-de-privacidad" element={<AvisoPrivacidad />} />
                    <Route path="/personaliza" element={<Personaliza />} />
                    <Route path="/producto/:id" element={<Product />} />
                    <Route path="/gracias" element={<Gracias />} />
                    <Route path="/quienes-somos" element={<QuienesSomos />} />
                    <Route path="/contactanos" element={<Contactanos />} />
                    <Route path="/Galeria" element={<Galeria />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;