import React from 'react';
import Header from '../components/Header.js';
import Section from '../components/Section.js';
import Footer from '../components/Footer';
import { Row, Container, Col } from 'react-bootstrap';
/**
 * Pagina que muestra los términos y condiciones
 * @returns 
 */
function TerminosCondiciones(){
    return(
        <>
            <Header />
            <Section fullHeight={true} className={'terminos-condiciones-section pb-5'}>
                <Container>
                    <Row>
                        <Col>
                            <h2 className="text-center pt-5 mb-0 titulo-legales">AVISO DE PRIVACIDAD</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-4 pb-5 pb-md-1">
                            <p className="pl-md-5 pr-md-5 text-justify">
                                En Orange Box estamos comprometidas con la protección de tus datos personales, asumiendo la responsabilidad del uso, manejo, almacenamiento y confidencialidad los mismos de acuerdo a lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (LFPDPPP) y con el fin de darte a conocer la información relativa a las características principales del tratamiento al que serán sometidos tus datos personales, emite el presente Aviso de Privacidad.
                            </p>
                            <p className="pl-md-5 pr-md-5 text-justify">
                                Si el Usuario utiliza los servicios de la página de Orange Box, significa que ha leído, entendido y acordado los términos antes expuestos. El Usuario manifiesta que, al uso de la página, la proporción de sus Datos Personales, o la realización directa de origen contractual o extracontractual con Orange Box, ya ha leído el presente aviso y consciente lo estipulado en el mismo.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2 className="text-center pt-5 mb-0 titulo-legales">FINALIDAD DE TRATAMIENTO DE DATOS PERSONALES</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-4 pb-5 pb-md-1">
                            <p className="pl-md-5 pr-md-5 text-justify">
                                Daremos tratamiento a tus datos personales para:
                            </p>
                            <ul className="text-justify">
                                <li>El cumplimiento de la relación contractual derivada de la solicitud y/o contratación de nuestros productos o servicios.</li>
                                <li>Verificar, confirmar y validar tu identidad.</li>
                                <li>Administrar, operar y dar seguimiento a los servicios y productos que solicitas o contratas con nosotros.</li>
                                <li>Cumplir con las obligaciones establecidas por la legislación aplicable y las autoridades competentes.</li>
                            </ul>
                            <p className="pl-md-5 pr-md-5 text-justify">
                                De forma adicional, Orange Box utiliza tu información personal para:
                            </p>
                            <p className="pl-md-5 pr-md-5 text-justify">
                                Envío de ofertas, promociones y publicidad de nuestros productos.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2 className="text-center pt-5 mb-0 titulo-legales">DATOS PERSONALES SUJETOS A TRATAMIENTO</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-4 pb-5 pb-md-1">
                            <p className="pl-md-5 pr-md-5 text-justify">
                                Para el cumplimiento de las finalidades previamente establecidas, Orange Box da tratamiento a los siguientes datos personales que serán recibidos por medio de nuestro sitio web:
                            </p>
                            <p className="pl-md-5 pr-md-5 text-justify">
                                Nombre completo
                            </p>
                            <p className="pl-md-5 pr-md-5 text-justify">
                                Información de contacto: Teléfono y correo electrónico
                            </p>
                            <p className="pl-md-5 pr-md-5 text-justify">
                                Información demográfica: Domicilio para la entrega del pedido. 
                            </p>
                            <p className="pl-md-5 pr-md-5 text-justify">
                                Información fiscal para facturación. 
                            </p>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <h2 className="text-center pt-5 mb-0 titulo-legales">DEVOLUCIONES</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-4 pb-5 pb-md-1">
                            <p className="pl-md-5 pr-md-5 text-justify">
                                Te pedimos revises muy bien tu pedido al momento de recibirlo, de ser posible, toma un video del momento en el que abres nuestro paquete con la finalidad de poder darle atención inmediata a tu aclaración.
                            </p>
                            <p className="pl-md-5 pr-md-5 text-justify">
                                Cualquier aclaración o necesidad de cambio sea solicitada dentro de las primeras 24 horas a partir de la entrega de tu pedido.
                            </p>
                            <p className="pl-md-5 pr-md-5 text-justify">
                                Dependiendo del caso, deliberaremos la reposición del producto o la devolución de tu dinero.
                            </p>
                            <p className="pl-md-5 pr-md-5 text-justify">
                                No podremos realizar cambios ni devoluciones sin video de apertura. 
                            </p>
                            <p className="pl-md-5 pr-md-5 text-justify">
                                Tienes un plazo máximo de 2 días para solicitar el reembolso de tu pedido a partir del día de entrega. Una vez concluido el plazo de 2 días naturales después de la realización de la entrega, no será procedente ninguna solicitud de devolución. 
                            </p>
                            <p className="pl-md-5 pr-md-5 text-justify">
                                Para solicitar tu devolución, adicional a tu vídeo de recepción de pedido, es importante enviar un número de cuenta o tarjeta de débito a nombre del titular del pedido. 
                            </p>
                            <p className="pl-md-5 pr-md-5 text-justify">
                                Los tiempos aproximados para realizar el proceso de reembolso una vez autorizado, será de 10 a 15 días hábiles. 
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Section>
            <Footer/>
        </>
    );
}

export default TerminosCondiciones;