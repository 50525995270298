import { TOKEN_API_MAIL, URL_API_MAIL } from '../config/Keys';
import axios from 'axios';

/**
 * 
 * @param {*} from 
 * @param {*} to 
 * @param {*} subject 
 * @param {*} body 
 */
function SendMail(from, to, subject, body, template, params) {
    return new Promise((resolve, reject) => {
        try{
            const data = new FormData();
            data.append('token', TOKEN_API_MAIL);
            data.append('from', from);
            data.append('to', to);
            data.append('subject', subject);
            if (typeof template === 'undefined' || template === '') {
                data.append('body', body);
                data.append('type', 'text/html');
            } else {
                data.append('template', template);
                data.append('params', JSON.stringify(params));
            }
            axios.post(URL_API_MAIL, data)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let message = '';
                    if (error.response && typeof error.response.data !== 'undefined'
                        && typeof error.response.data.message !== 'undefined') {
                        message = error.response.data.message;
                        if (isHTML(message) && typeof error.message !== 'undefined' && error.message !== '')
                            message = error.message;
                    } else if (error.request && typeof error.request.responseText !== 'undefined'
                        && error.request.responseText !== '' && error.request.responseText.indexOf('{"status"') === -1) {
                        message = error.request.responseText;
                        if (isHTML(message) && typeof error.message !== 'undefined' && error.message !== '')
                            message = error.message;
                    } else {
                        if (error.message === 'Network Error')
                            message = "No se logro establecer conexión con el servidor remoto, verifique su acceso a Internet.";
                        else
                            message = error.message;
                    }
                    reject({ status: false, message: message });
                });
        }catch(e){
            reject({ status: false, message: e.message });
        }
    });
}

/**
 * Validamos si el texto es HTML
 * @param text 
 * @returns 
 */
function isHTML(text) {
    let regex = /<("[^"]*"|'[^']*'|[^'">])*>/;
    return regex.test(text);
}

export default SendMail;