import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

/**
 * Footer del sitio
 * @returns 
 */
function Footer(props){
    return(
        <footer className={ (typeof props !== undefined && props.className !== undefined) ? props.className : ""}>
            <Container>
                <Row>
                    <Col xs={12} className={'text-center pb-2'}>
                        <Link to="/terminos-y-condiciones">Términos y condiciones / Aviso de Privacidad</Link>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;