import React from 'react';
import Header from '../components/Header.js';
import Section from '../components/Section.js';
import { Container, Row, Col } from 'react-bootstrap';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import Footer from '../components/Footer';
import Gallery from '../config/Gallery';

/**
 * Pagina que muestra la galería
 * @returns 
 */
function Galeria(){
    return(
        <>
            <Header />
            <Section fullHeight={true} className={'gallery-section pt-5 pb-5'}>
                <Container clasName={'mt-5 mb-5'}>
                    <Row>
                        <Col xs={12} lg={{span:10, offset:1}} xl={{span:8, offset:2}}>
                            <h2 className={'title-section mt-3'}>Galería</h2>
                        </Col>
                        <Col xs={12} lg={{span:10, offset:1}} className={'mt-4 pb-5'}>
                        <ResponsiveMasonry
                                columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
                            >
                                <Masonry className={'gallery'}>
                                    {Gallery.map((image, i) => (
                                        <img
                                            key={i}
                                            src={image}
                                            style={{width: "100%", display: "block"}}
                                            alt=""
                                        />
                                    ))}
                                </Masonry>
                            </ResponsiveMasonry>
                        </Col>
                    </Row>
                </Container>
            </Section>
            <Footer/>
        </>
    );
}

export default Galeria;