import React from 'react';
import Header from '../components/Header.js';
import Section from '../components/Section.js';
import Footer from '../components/Footer';
import { Row, Container, Col, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";

/**
 * Pagina de error 404
 * @returns 
 */
function NotFound(){
    return(
        <>
            <Header />
            <Section fullHeight={true} className={'not-found-section justify-content-center align-items-center d-flex'}>
                <Container className="text-center">
                    <Row>
                        <Col>
                            <h6 className={'error-404'}>404</h6>
                            <p>La página que buscas no existe.</p>
                            <Link to="/"><Button className={'btn btn-orange'}>Regresar</Button></Link>
                        </Col>
                    </Row>
                </Container>
            </Section>
            <Footer/>
        </>
    );
}

export default NotFound;